<template>
  <div class="container new-event-container mb-5 d-flex flex-column justify-content-between">
    <div style="flex: 1 1 10%;" class="mb-5 d-flex flex-column justify-content-center align-items-center mb-3 app-header p-2" :class="{'gap-4': !mobileMode, 'gap-3': mobileMode }">
      <img :src="this.logoUrl" class="app-logo" style="height: 80px">
    </div>

    <div style="flex: 1 1 80%" v-if="screen === 'initial'">
      <div v-if="screen === 'initial' && !mobileMode" class="d-flex flex-column justify-content-around h-100">
        <div class="row g-4 event-cards">
          <div class="event-card col br-12 shadow-sm d-flex flex-column align-items-center" @click="changeScreen('find_by_code')">
            <div class="flex-grow-1 d-flex justify-content-center align-items-center"></div>
            <div class="flex-grow-1 event-card-body d-flex flex-column align-items-center p-4">
              <img :src="leftCardImage" class="img-fluid sq-150">
              <div class="mt-3 d-flex justify-content-center align-items-center">
                <p class="fw-bold card-title text-center">Está com a multa por perto?</p>
              </div>
              <div class="separator-line"></div>
              <div class="mt-3 d-flex justify-content-center align-items-center">
                <p class="fw-medium card-subtitle fs-5 text-center">Pesquisar pelo código da infração</p>
              </div>
            </div>
          </div>

          <div class="event-card col br-12 shadow-sm d-flex flex-column align-items-center" @click="changeScreen('find_by_most_common')">
            <div class="flex-grow-1 d-flex justify-content-center align-items-center"></div>
            <div class="flex-grow-1 event-card-body d-flex flex-column align-items-center p-4">
              <img :src="middleCardImage" class="img-fluid sq-150">
              <div class="mt-3 d-flex justify-content-center align-items-center">
                <p class="fw-bold card-title text-center">Não está com a multa mas sabe qual a infração?</p>
              </div>
              <div class="separator-line"></div>
              <div class="mt-3 d-flex justify-content-center align-items-center">
                <p class="fw-medium card-subtitle fs-5 text-center">Pesquisar infrações existentes</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="d-flex flex-column justify-content-between h-100">
        <div class="event-cards" style="height: 85%" v-if="screen === 'initial' && mobileMode">
          <div class="event-card col br-12 shadow-sm d-flex flex-column align-items-center" @click="changeScreen('find_by_code')">
            <div class="event-card-body d-flex flex-column align-items-center">
              <img :src="leftCardImage" class="img-fluid sq-60 mb-3">
              <p class="fw-bold fs-4 text-center card-title">Está com a multa por perto?</p>
              <p class="fw-medium fs-5 text-center card-subtitle">Pesquisar pelo código da infração</p>
            </div>
          </div>

          <div class="event-card col br-12 shadow-sm d-flex flex-column align-items-center p-3" @click="changeScreen('find_by_most_common')">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <img :src="middleCardImage" class="img-fluid sq-60 mb-3">
              <p class="fw-bold fs-4 text-center card-title">Não está com a multa mas sabe qual a infração?</p>
              <p class="fw-medium fs-5 text-center card-subtitle">Pesquisar infrações existentes</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="middle-screen" style="flex: 1 1 70%;" v-if="screen !== 'initial'">
      <div class="new-event-option-group" v-show="screen === 'find_by_code'">
        <p class="section-header-text">Procure na sua infração o "Código da infração", um número de 4 dígitos, e digite-o no campo abaixo:</p>
        <div class="d-flex justify-content-center align-items-center">
          <input ref="codeInput"
            v-model="findByCode"
            v-input-mask mask="9999"
            class="form-control string required event-code-input mt-3">
        </div>

        <div class="mt-3"></div>

        <p class="fw-bold fs-5" v-if="filteredFlowOptions.length === 1">Infração encontrada! Confirme e clique em continuar.</p>
        <p class="fw-bold fs-5" v-if="filteredFlowOptions.length > 1">Selecione uma das infrações encontradas e clique em continuar.</p>
        <p class="fw-bold fs-5 text-danger p-3 br-8 justify-contenet-center align-items-center" v-if="filteredFlowOptions.length === 0 && findByCode.replace('_', '').length === 4">Nenhuma infração encontrada.</p>
        <p class="event-option br-12 shadow-sm d-flex p-3 mx-2"
          v-for="option in filteredFlowOptions" :key="`byCode-${option.id}`"
          @click="selectFlow(option.id)"
          :class="{'selected': (selectedFlowId === option.id)}">
          {{option.description}}
        </p>
      </div>

      <div id="find_by_most_common" v-show="screen === 'find_by_most_common'">
        <p class="fw-bold section-header-text">Selecione a infração que você cometeu ou role até "Nenhuma das opções" caso não encontre.</p>
        <div class="row g-2 event-options">
          <div class="col-12 col-md-6" v-for="option in mostCommonInfractions" :key="`byMostCommon-${option.id}`">
            <p class="event-option br-12 shadow-sm d-flex align-items-center"
              @click="selectFlow(option.id)"
              :class="{'selected': (selectedFlowId === option.id)}">
              <span class="event-option-highlight d-flex justify-content-center align-items-center text-center">{{option.highlighted}}</span>
              <span class="flex-grow-1 event-option-body">{{option.description}}</span>
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p class="event-option text-danger br-12 shadow-sm d-flex justify-content-start align-items-center"
              @click="changeScreen('find_by_select')">
              <span class="d-block text-center fs-5 mx-auto">Nenhuma das opções</span>
            </p>
          </div>
        </div>
      </div>

      <div id="find_by_select" class="new-event-option-group" v-show="screen === 'find_by_select'">
        <p class="fw-bold section-header-text">Digite parte da descrição da infração para filtrar as opções.</p>
        <input ref="findBySelectInput"
          class="form-control string required event-description-input mb-4"
          v-model="findBySelect">
        <div class="all-flow-options-div">
          <p class="event-option br-12 shadow-sm d-flex align-items-center p-3"
            v-for="option in filteredFlowOptions" :key="`bySelect-${option.id}`"
            @click="selectFlow(option.id)"
            :class="{'selected': (selectedFlowId === option.id)}">
            {{option.description}}
          </p>
        </div>
      </div>
    </div>

    <div style="flex: 1 1 15%;" v-if="screen !== 'initial'">
      <div class="spacer-40" v-if="!mobileMode"></div>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <form action="/events" id="new_user_form" method="post" ref="loginForm" v-if="screen !== 'initial'"
          @submit="validateBeforeSubmit">
          <input type="password" name="event[flow_id]" v-model="selectedFlowId" v-show="false">
          <input type="password" name="event[identifier]" v-model="eventIdentifier" v-show="false">
          <button type="submit"
            class="btn-signup mb-3" :class="{disabled: !selectedFlowId || isLoading}">
            {{this.selectedFlowId ? "Continuar" : "Selecione uma infração para continuar"}}
          </button>
        </form>

        <p class="btn btn-secondary new-event-return-to-options mt-3" v-if="screen !== 'initial'" @click="changeScreen('initial')">
          Voltar
        </p>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import leftCardImage from 'images/icons/event_new_left_card.png';
import middleCardImage from 'images/icons/event_new_middle_card.png';

export default {
  el: "application",
  computed: {
    eventIdentifier() {
      return document.querySelector("#event-identifier").innerHTML
    },
    flows() {
      return JSON.parse(document.querySelector("#flows-json").innerHTML)
    },
    logoUrl() {
      return document.querySelector("#logo-url").innerHTML;
    },
    mostCommonInfractions() {
      return [
        {highlighted: "Avançar sinal",description: "Avançar o sinal vermelho", id: this.flows.find(f => f.description.includes("Avançar o sinal vermelho")).id},
        {highlighted: "Velocidade", description: "Excesso de velocidade em até 20%", id: this.flows.find(f => f.description.includes("à máxima em até 20%")).id},
        {highlighted: "Velocidade", description: "Excesso de velocidade de 20 à 50%", id: this.flows.find(f => f.description.includes(" à máxima em mais de 20%")).id},
        {highlighted: "Velocidade", description: "Excesso de velocidade acima de 50%", id: this.flows.find(f => f.description.includes("à máxima em mais de 50%")).id},
        {highlighted: "Cinto de segurança", description: "Dirigir sem cinto de segurança", id: this.flows.find(f => f.description.includes("condutor ou passageiro de usar o cinto de segurança")).id},
        {highlighted: "Celular", description: "Dirigir ao celular", id: this.flows.find(f => f.description.includes("segurando ou manuseando telefone")).id},
        {highlighted: "Faixa de ônibus", description: "Dirigir na faixa de ônibus", id: this.flows.find(f => f.description.includes("de transporte público coletivo de passageiros")).id},
        {highlighted: "Sem CNH", description: "Dirigir sem possuir uma CNH ou PPD", id: this.flows.find(f => f.description.includes("sem possuir Carteira Nacional de Habilitaçã")).id},
        {highlighted: "Ultrapassagem", description: "Ultrapassar em faixa contínua", id: this.flows.find(f => f.description.includes("ela contramão outro veículo onde houver marcação viária")).id},
      ]
    },
    mobileMode() {
      return document.querySelector("#mobile-mode").innerHTML === "true";
    },
    landingUrl() {
      return document.querySelector("#landing-url").innerHTML + "#faq";
    }
  },
  watch: {
    findByCode: function (newVal) {
      const sanitizedVal =  newVal.replace('_', '')
      if (sanitizedVal.length === 4) {
        this.filteredFlowOptions = this.flows.filter(flow => flow.law.substring(0, 4) === sanitizedVal)
        if (this.filteredFlowOptions.length === 1) this.selectedFlowId = this.filteredFlowOptions[0].id
        if (this.filteredFlowOptions.length >= 1) this.$nextTick(() => this.$refs.codeInput.blur())
      } else {
        this.filteredFlowOptions = []
        this.selectedFlowId = null;
      }
    },
    findBySelect: function (newVal) {
      if (newVal.length) {
        this.filteredFlowOptions = this.flows.filter(flow => flow.description.toLowerCase().includes(newVal.toLowerCase()))
        if (this.filteredFlowOptions.length === 1) this.selectedFlowId = this.filteredFlowOptions[0].id
      } else {
        this.filteredFlowOptions = this.flows
      }
      if (!this.filteredFlowOptions.map(opt => opt.id).includes(this.selectedFlowId)) this.selectedFlowId = null;
      if (document.querySelector(".all-flow-options-div")) {
        document.querySelector(".all-flow-options-div").scrollTop = 0; // For Safari
        document.querySelector(".all-flow-options-div").scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    },
  },
  data: function () {
    return {
      screen: "initial",
      findByCode: "",
      findBySelect: "",
      selectedFlowId: "",
      filteredFlowOptions: [],
      isLoading: false,
      leftCardImage,
      middleCardImage,
    }
  },
  methods: {
    changeScreen(screen) {
      this.screen = screen;
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      if (document.querySelector(".middle-screen")) {
        document.querySelector(".middle-screen").scrollTop = 0; // For Safari
        document.querySelector(".middle-screen").scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
      if (screen === "find_by_code") {
        this.filteredFlowOptions = [];
        this.findByCode = "";
        this.$nextTick(() => this.$refs.codeInput.focus())
      }
      if (screen === "find_by_select") {
        this.filteredFlowOptions = this.flows;
        this.$nextTick(() => this.$refs.findBySelectInput.focus())
      }
      this.selectedFlowId = null;

      this.trackEvent(screen);
    },
    async trackEvent(screen) {
      await axios.patch(`/client/events/${this.eventIdentifier}/track`, { trackeable: screen });
    },
    selectFlow(flowId) {
      this.selectedFlowId = flowId;
    },
    validateBeforeSubmit(e) {
      if (!this.selectedFlowId) {
        e.preventDefault();
      } else {
        this.isLoading = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new-event-container {



  @media screen and (max-width: 768px) {
    margin-top: 20px;
    padding: 0 20px !important;
  }
}
.event-cards>.event-card {
  transition: all 200ms;
}

.event-cards:hover > .event-card {
  cursor: pointer;
  opacity: 0.25;
  transform: scale(0.95);
  background-color: #FFDE00;
}
.event-cards:hover > .event-card:hover {
  cursor: pointer;
  opacity: 1;
  transform: scale(1.05);
}

.event-card {
  margin: 1rem;
  background-color: #ffffff;
  border: 3px solid #FFDE00;
  box-shadow: inset 0 0 0 3px #000000;

  @media screen and (max-width: 768px) {
    height: 30%;
    margin-bottom: 10px;
    .card-title {
      font-size: 16px;
    }
    .card-subtitle {
      font-size: 14px;
    }
  }
}
.event-options:hover > div {
  cursor: pointer;
  opacity: 0.6;
}
.event-options:hover > div:hover {
  cursor: pointer;
  opacity: 1;
}
.event-option {
  transition: 200ms;
  &:hover {
    background: #000000;
    color: white;
    cursor: pointer;
    span {
      background: transparent;
      color: white;
    }
  }
  &.selected {
    background: #079507;
    color: white;
    span {
      color: white;
    }
  }
  height: 80px;
  margin-right: 12px;
  @media screen and (max-width: 768px) {
    margin-right: 0;
    height: 80px;
  }
  .event-option-highlight {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    color: rgb(0, 0, 0);
    background: #FFDE00;
    border-radius: 12px 0 0 12px;
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
    height: 100%;
    flex: 0 0 30%;
    @media screen and (max-width: 768px) {
      flex: 0 0 50%;
    }
  }
  .event-option-body {
    flex: 0 0 70%;
    padding: 30px;
    @media screen and (max-width: 768px) {
      flex: 0 0 50%;
      padding: 8px;
    }
  }
}
.event-code-input {
  text-align: center;
  padding: 20px;
  height: 100px;
  font-size: 70px;
  border-radius: 12px;
  width: 50%;
  @media screen and (max-width: 768px) {
    text-align: center;
    height: 50px;
    font-size: 30px;
    border-radius: 12px;
    width: 90%;
  }
}
.event-description-input {
  padding: 20px;
  height: 60px;
  font-size: 16px;
  border-radius: 12px;
  width: 100%;
  @media screen and (max-width: 768px) {
    height: 30px;
    font-size: 14px;
    width: 98%;
  }
}
.new-event-option-group {
  .event-option {
    font-size: 14px;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    .event-option {
      font-size: 12px;
    }
  }
}
.all-flow-options-div {
  width: 100%;
  overflow-y: auto;
  padding: 12px;
  .event-option {
    font-size: 14px;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    .event-option {
      font-size: 12px;
    }
  }
}
.most-common {
  padding: 40px;
  max-height: 60vh;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
}
.section-header-text {
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}
.card-title {
  font-size: 28px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}
.card-subtitle {
  font-size: 22px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}
</style>