<template>
  <div id="chat-app">
    <div id="app-container">
      <div id="app-chat">
        <!-- Desktop Header -->
        <div id="app-header" class="d-flex align-items-center ps-3" v-if="!mobileMode">
          <img :src="this.logoUrl" class="app-logo clickable" style="height: 50px; max-width: 100%; margin-right: 20px;" @click="goToHome">
          <div class="col-md-7 col-12">
            <p class="m-0 fs-4">Painel de Atendimento</p>
            <p class="m-0 fs-4" v-if="currentQuestionNumber">Pergunta {{currentQuestionNumber}} de {{totalQuestionCount}}</p>
          </div>
          <div class="col-md-2 col-12">
            <button class="btn btn-danger w-100" :class="{'py-2': mobileMode, 'py-4': !mobileMode}" @click="rollback" v-if="canRollback">
              Voltar pergunta
            </button>
          </div>
        </div>

        <!-- Mobile Header -->
        <div v-else id="app-header" class="pt-4 text-center">
          <p class="m-0 fs-3 fw-bold clickable" @click="goToHome">Avarin Multas</p>
          <p class="m-0 fs-4 mb-2">Painel de atendimento</p>
          <p class="m-0 fs-5" v-if="currentQuestionNumber">Pergunta {{currentQuestionNumber}} de {{totalQuestionCount}}</p>
          <div class="col-md-2 col-12">
            <button class="btn btn-danger w-100 mt-2" :class="{'py-2': mobileMode, 'py-4': !mobileMode}"
              @click="rollback" v-if="canRollback">
              Voltar pergunta
            </button>
          </div>
        </div>

        <!-- Message List -->
        <div id="app-message-list">
          <div class="app-message-container">
            <template v-if="loaded">
              <message v-for="message in messages" :message="message" :key="message.id" />
              <waiting-message v-if="writingAnimation" />
              <answer-choices v-if="answerChoices.length" :answer-choices="answerChoices" @answer="answer" />

              <div class="d-flex flex-column justify-content-center align-items-center" v-if="!answerChoices.length && !loading">
                <br>
                <p @click="concludeEvent" class="my-4 btn-signup mb-5">Continuar</p>
                <br>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import message from '../components/message.vue'
import answerChoices from '../components/answer_choices.vue'
import waitingMessage from '../components/waiting_message.vue'

export default {
  el: "application",
  components: {
    message,
    waitingMessage,
    answerChoices,
  },
  computed: {
    eventId() {
			return window.location.pathname.match(/chat\/([^&]+)/)[1]
      // return window.location.pathname.replace( /^\D+/g, '');
    },
    resourceUrl() {
      return `/client/chat/${this.eventId}`;
    },
		mobileMode() {
			return document.querySelector("#mobile-mode").innerHTML === "true";
		},
    logoUrl() {
      return document.querySelector("#logo-url").innerHTML;
    },
		rootPathForUser() {
			return document.querySelector("#root-path-for-user").innerHTML;
		},
		canRollback() {
			return this.messages.length > 3;
		}
  },
  data: function () {
    return {
      messages: [],
      loaded: false,
      writingAnimation: false,
      answerChoices: [],
      loading: false,
			concludeDeactivated: false,
			totalQuestionCount: 0,
			currentQuestionNumber: 0,
    }
  },
  mounted() {
    this.fetchMessages();
  },
  methods: {
    async fetchMessages() {
      const response = await axios.get(this.resourceUrl);
			this.printMessage({sender: "application", id: 0, content : `Olá, eu sou o Avarin! Responda as perguntas abaixo para que eu possa encontrar os melhores argumentos para sua defesa de multa. Você pode clicar em "Voltar Pergunta" para corrigir alguma resposta.`})
      this.messages = [...this.messages, ...response.data.messages];
      this.answerChoices = response.data.answer_choices;
			this.totalQuestionCount = response.data.total_question_count;
			this.currentQuestionNumber = response.data.current_question_number;
			this.scrollToTop();
      if (this.canRollback) this.scrollToBottom();

      this.loaded = true;

			if (!response.data.started) this.start();
    },
    async start() {
      const response = await axios.patch(this.resourceUrl);
      if (response.data.success) {
        response.data.push_messages.forEach(async (message) => {
          await this.printMessage(message);
        })
        this.answerChoices = response.data.event_question_options;
				this.totalQuestionCount = response.data.total_question_count;
				this.currentQuestionNumber = response.data.current_question_number;
        this.scrollToTop();
      } else {
        console.log("fail")
      }
    },
    async printMessage(message) {
      this.messages.push(message)
    },
    async answer(choice) {
      this.answerChoices = [];
      this.loading = true;
      const response = await axios.post(this.resourceUrl, { option_id: choice.id });
      if (response.data.success) {
        response.data.push_messages.forEach(async (message) => {
          await this.printMessage(message);
        })
        this.answerChoices = response.data.event_question_options;
				this.totalQuestionCount = response.data.total_question_count;
				this.currentQuestionNumber = response.data.current_question_number;
        this.scrollToBottom();
        this.loading = false;
      } else {
        console.log("fail")
      }
    },
    async concludeEvent() {
			if (!this.concludeDeactivated) window.location = `/events/${this.eventId}/conclude`;
			this.concludeDeactivated = true;
			setTimeout(() => this.concludeDeactivated = false, 20000);
    },
    async rollback() {
      const response = await axios.delete(this.resourceUrl);
      if (response.data.success) {
        this.messages = this.messages.filter((message) => {
          return !response.data.message_ids_to_remove.includes(message.id)
        })
        this.answerChoices = response.data.answer_choices;
				this.totalQuestionCount = response.data.total_question_count;
				this.currentQuestionNumber = response.data.current_question_number;
        this.scrollToBottom();
      } else {
        console.log("fail")
      }
    },
    scrollToBottom() {
      const messageList = document.getElementById("app-message-list");
      setTimeout(() => {
        messageList.scrollTop = messageList.scrollHeight;
      }, 10)
    },
		scrollToTop() {
      const messageList = document.getElementById("app-message-list");
      setTimeout(() => {
        messageList.scrollTop = 0;
      }, 10)
    },
    goToHome() {
      window.location = this.rootPathForUser;
    },
  }
}
</script>

<style lang="scss" scoped>
  #app-container {
    height: 100vh;
    width: 100vw;
  }
  #app-icon img {
    width: 70px;
    position: fixed;
    bottom: 40px;
    right: 40px;
  }
  #app-chat {
    height: 100%;
    width: 100%;
  }
  #app-header {
    background-color: #FFDE00;
    height: 15vh;
    color: white;
    box-shadow: 0 1px 4px rgba(0,0,0,.2);
    position: relative;
    box-sizing: border-box;

		@media screen and (max-width: 768px) {
			height: 20vh;
		}
  }
  @media (max-width: 450px) {
    .sc-header {
      border-radius: 0px;
    }
  }
  #app-message-list {
    width: 100%;
    height: 85vh;
    overflow-y: scroll;

		@media screen and (max-width: 768px) {
			height: 80vh;
		}
  }
  .app-message-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 25px 25px;
    height: 100%;
  }
  #app-user-input {
    height: 12%;
    width: 100%;
    margin: 0px;
    position: relative;
    bottom: 0;
    display: flex;
    background-color: #f4f7f9;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: background-color .2s ease,box-shadow .2s ease;
  }
  #app-user-input-text {
    width: 100%;
    resize: none;
    border: none;
    outline: none;
    border-bottom-left-radius: 10px;
    box-sizing: border-box;
    padding: 18px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.33;
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: rgba(240,240,245,1);
    color: #565867;
    -webkit-font-smoothing: antialiased;
    max-height: 200px;
    overflow: scroll;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    word-wrap: break-word
  }
  #app-user-input-text::placeholder {
    font-family: arial;
  }
  #app-user-input-text:focus {
    background-color: white;
    color: #565867;
    border: 2px solid rgba(200,200,230,1);
    transition: 200ms ease;
  }
  #app-user-input-text:focus::before {
    content: "";
    position: fixed;
    top: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);
    z-index: 100;
  }
  #app-user-input--text:empty:before {
    content: attr(placeholder);
    display: block; /* For Firefox */
    color: rgba(86, 88, 103, 0.3);
    outline: none;
  }

  .no-message-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .no-message-content {
    background: white;
    width: 60%;
    border: 1px solid rgba(200,200,200,1);
    box-shadow: 1px 1px rgba(220,220,220,1);
    padding: 40px 60px;

		@media screen and (max-width: 768px) {
			width: 90%;
			padding: 10px 10px;
		}
  }
  .no-message-title {
    font-size: 24px;
    font-weight: bold;
    color: rgb(30,30,30);
  }
  .no-message-description {
    font-size: 14px;
    font-weight: bold;
    color: rgb(70,70,70);

		@media screen and (max-width: 768px) {
			margin-bottom: 30px;
		}
  }
	.continue-btn {
		@media screen and (max-width: 768px) {
			margin-bottom: 120px !important;
		}
	}
</style>
