document.addEventListener("DOMContentLoaded", () => {
  paymentContainer = document.querySelector(".invoice-container")
  
  if (!paymentContainer) return;

  const eventId = paymentContainer.dataset.eventid;
  let stopPolling = false;

  if (!eventId) return;

  const poolFunction = async () => {
    if (stopPolling) return;
    const url = `/events/${eventId}/status.json`

    const response = await axios.get(url);

    console.log(response)
  
    if (response.data === "finished") {
      document.querySelector(".payment-confirmed").classList.remove("hide")
      document.querySelector(".payment-container").classList.add("hide")
      document.querySelector(".invoice").classList.add("hide")
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      stopPolling = true;
    }
  }

  poolFunction();

  setInterval(poolFunction, 4000)
})
